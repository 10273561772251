import React, { useEffect, useState } from 'react';
import { Card, Loader, Button, Grid, Modal, Icon, Menu, Dropdown, Pagination, Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import "../App.css"
import { jobRoleOptions, yoeOptions } from '../displayHelpers';
import { analytics } from '../firebase-config';
import { logEvent } from "firebase/analytics";
import CallToActionBanner from './CallToActionBanner';
import JobCard from './JobCard';

const LIMIT = 10;

const JobsList = ({isLoggedIn, currentRole, flags}) => {
    const [jobs, setJobs] = useState([]);
    const [resultingCompanies, setResultingCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedJobDescription, setSelectedJobDescription] = useState("");
    const [filters, setFilters] = useState({
        role: '',
        yearsOfExperience: '',
        companies: []
    });

    const isAdmin = () => {
        return currentRole === 'admin'
    }

    const createTailoredResume = async(jobId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/make-tailored-resume/${jobId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                toast.success('Created Tailored Resume for job!');
            } else {
                toast.error('Failed to create Tailored Resume for job!');
            }
        } catch (error) {
            console.error('Failed to create tailored resume:', error);
            toast.error('Failed to create Tailored Resume for job!');
        }
    }

    const toggleModal = (description) => {
        setSelectedJobDescription(description); // Set the description to state
        setModalOpen(!modalOpen); // Toggle the state of the modal visibility
    };

    const handleFilterChange = (e, { name, value }) => {
        setFilters(prev => ({ ...prev, [name]: value }));
        setCurrentPage(1); // Reset to first page
    };

    const handleCompanyChange = (e, { value }) => {
        setFilters(prev => ({ ...prev, companies: value }));
        setCurrentPage(1);
    };
    

    const addToMyJobs = async(jobId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/applications/add/${jobId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                toast.success('Added job to applications!');
            } else if (response.status === 409) {
                const data = await response.json();
                toast.warn(data.message || "You have already added this application");
            } 
            else {
                toast.error('Failed to add to applications!');
            }
        } catch (error) {
            console.error('Failed to add to applications:', error);
            toast.error('Failed to add to applications!');
        }
    }

    const fetchJobs = async (page = 1) => {
        setLoading(true);
        const queryParams = new URLSearchParams({
            ...filters,
            companies: filters.companies.join(','),
            skip: (currentPage - 1) * LIMIT,
            limit: LIMIT
        }).toString();
        
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            const data = await response.json();
            setJobs(data.jobs);
            setResultingCompanies(data.companies);
            setTotalPages(Math.ceil(data.total / 10));
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch jobs:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchJobs();
    }, [filters, currentPage]);

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
        fetchJobs(activePage);
    };

    const openJobLink = (url) => {
        window.open(url, '_blank');
    };

    const handleExperienceChange = async (event, jobId, newYoe) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/${jobId}`, {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ years_of_experience: newYoe }),
            });
            if (response.ok) {
                toast.success('Updated job successfully!');
                fetchJobs();
            } else {
                toast.error('Failed to update!');
            }
        } catch (error) {
            toast.error('Failed to update job.');
            console.error('Failed to update job:', error);
        }
    };

    const deleteJob = async (jobId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/jobs/${jobId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (!response.ok) {
                toast.error("Error deleting job")
                return
            }
            fetchJobs();
            toast.success('Removed job successfully!');
        } catch (error) {
            toast.error('Failed to remove job.');
            console.error('Failed to delete job:', error);
        }
    };

    if (loading) {
        return <Loader active inline='centered' />;
    }

    const withNoSelection = (optionsGiven) => {
        optionsGiven.push({
            key: 'none',
            value: 'none',
            text: 'No selection'
        })
        return optionsGiven
    }

    const isPaidMember = localStorage.getItem('isPro') === 'true';

    return (
        <Grid padded>
            {!isPaidMember ? <CallToActionBanner message={'You are currently seeing jobs from 4 days ago. You need a Pro subscription to see the freshest jobs!'} buttonLink={'/account'} buttonText={'Go to Subscriptions Page'} /> : null}
            <Grid.Row>
                <Menu stackable>
                <Menu.Item header>
                    Filters
                </Menu.Item>
                    <Dropdown
                        style={{margin: 10}}
                        placeholder='Select Role'
                        fluid
                        selection
                        value={filters.role}
                        options={withNoSelection(jobRoleOptions())}
                        onChange={handleFilterChange}
                        name='role'
                    />
                    <Dropdown
                        style={{margin: 10}}
                        placeholder='Years of Experience'
                        fluid
                        selection
                        value={filters.yearsOfExperience}
                        options={withNoSelection(yoeOptions())}
                        onChange={handleFilterChange}
                        name='yearsOfExperience'
                    />
                    <Dropdown
                        style={{margin: 10}}
                        placeholder='Select Companies'
                        search
                        multiple
                        selection
                        value={filters.companies}
                        options={
                            resultingCompanies
                            .map(company => ({
                                key: company,
                                text: company,
                                value: company
                            }))
                        }
                        onChange={handleCompanyChange}
                        name='companies'
                    />
                </Menu>
            </Grid.Row>
            <Grid.Row>
            {jobs.length > 0 ?              
            <Card.Group>
                    {jobs.map((job, index) => (
                        <JobCard
                            job={job} 
                            isLoggedIn={isLoggedIn}
                            isAdmin={isAdmin}
                            flags={flags}
                            toggleModal={toggleModal}
                            openJobLink={openJobLink}
                            createTailoredResume={createTailoredResume}
                            addToMyJobs={addToMyJobs}
                            handleExperienceChange={handleExperienceChange}
                            deleteJob={deleteJob}
                            analytics={analytics}
                            logEvent={logEvent}
                            yoeOptions={yoeOptions}
                            index={index}
                            fetchJobs={fetchJobs}
                        />
                    ))}
                </Card.Group> :
                <Message info>
                    <Message.Header>No Jobs Found</Message.Header>
                    <p>No jobs match the selected criteria. Please adjust the filters to view more jobs.</p>
                </Message>
                }
                    <Modal open={modalOpen} onClose={() => setModalOpen(false)} dimmer="blurring">
                    <Modal.Header>Job Description</Modal.Header>
                    <Modal.Content>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{selectedJobDescription}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={() => setModalOpen(false)}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Grid.Row>
            <Grid.Row>
                <Pagination
                    activePage={currentPage}
                    onPageChange={handlePaginationChange}
                    totalPages={totalPages}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={null}
                    lastItem={null}
                />
            </Grid.Row>
        </Grid>
    );
};

export default JobsList;

import React, { useState, useEffect } from 'react';
import { Feed, Message, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { allowFeature } from '../feature_flags_service';

function timeAgo(date) {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
}

const mapTypeToDisplay = (type) => {
  switch (type) {
    case 'UserCreated':
      return 'New user joined!';
    case 'JobApplicationStatusChanged':
      return "Someone's making progress :)";
    case 'NewJobsUpload':
      return 'Fresh Jobs added!';
    case 'ResumeAdded':
      return 'New resume added!';
    case 'ProjectAdded':
      return 'New project added!';
    case 'UserSignedUpForCourse':
      return 'Someone started a Crash Course!';
    case 'HotCourse':
      return 'Hot Course Alert!';
    default:
      return 'Unknown event :(';
  }
};

const mapTypeToIcon = (type) => {
  switch (type) {
    case 'UserCreated':
      return 'user circle';
    case 'JobApplicationStatusChanged':
      return 'briefcase';
    case 'NewJobsUpload':
      return 'rocket';
    case 'ResumeAdded':
      return 'briefcase';
    case 'ProjectAdded':
      return 'paint brush';
    case 'UserSignedUpForCourse':
      return 'handshake outline';
    case 'HotCourse':
      return 'fire';
    default:
      return 'qq';
  }
};

const mapTypeToIconColor = (type) => {
  switch (type) {
    case 'UserCreated':
      return 'blue';
    case 'JobApplicationStatusChanged':
      return 'green';
    case 'NewJobsUpload':
      return 'purple';
    case 'ResumeAdded':
      return 'brown';
    case 'ProjectAdded':
      return 'teal';
    case 'UserSignedUpForCourse':
      return 'olive';
    case 'HotCourse':
      return 'orange';
    default:
      return 'qq';
  }
};

const EventFeedItem = ({ event }) => {

  const enhanceDescriptionWithLink = (eventDesc) => {
    // Regular expression to find 'Check it out!' case-insensitive followed by optional '.' or '!'
    const regex = new RegExp(`(check it out[.!]*)$`, 'i');
  
    // Remove 'Check it out!' if it exists, along with any following punctuation
    const cleanedText = eventDesc.replace(regex, '').trim();
    if (event.additionalInfo && event.additionalInfo.nav_link) {
      return (
        <>
          {cleanedText}
          <Link to={event.additionalInfo.nav_link} style={{marginLeft: '5px'}}>
          <strong>Check it out!</strong>
          </Link>
        </>
      );
    }
    return eventDesc;
  };

  return (
    <Feed.Event>
      <Feed.Label>
        <Icon name={mapTypeToIcon(event.eventType)} color={mapTypeToIconColor(event.eventType)}/>
      </Feed.Label>
      <Feed.Content>
        
        <Feed.Summary>
          {mapTypeToDisplay(event.eventType)} <Feed.Date>{timeAgo(event.createdAt)}</Feed.Date>
        </Feed.Summary>
        <Feed.Label>
        {enhanceDescriptionWithLink(event.eventDescription)}
        </Feed.Label>
      </Feed.Content>
    </Feed.Event>
  );
};

const getFilteredEvents = (events, flags) => {
  let typesToSkip = [];
  if (!allowFeature(flags, 'projects')) {
    typesToSkip.push('ProjectAdded');
  }
  if (!allowFeature(flags, 'resumes')) {
    typesToSkip.push('ResumeAdded');
  }
  return events.filter((ev) => !typesToSkip.includes(ev.eventType));
};

const EventsFeed = () => {
  const fetchFlags = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    setFlags(data);
  };

  const [events, setEvents] = useState([]);
  const [skip, setSkip] = useState(0);
  const [flags, setFlags] = useState([]);
  const limit = 10; // Number of events to fetch per call

  useEffect(() => {
    fetchFlags();
    loadMoreEvents(); // Load initial events
  }, []);

  const loadMoreEvents = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/events?skip=${skip}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );
    const data = await response.json();

    setEvents([...events, ...data.events]);
    setSkip(skip + limit);
  };

  return (
    <div style={{ padding: '20px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      {events.length ? (
        <Feed size="large">
          {events.map((event, index) => (
            <EventFeedItem key={index} event={event} />
          ))}
        </Feed>
      ) : (
        <Message info>Come back later...</Message>
      )}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button basic onClick={loadMoreEvents} primary>
          Load More...
        </Button>
      </div>
    </div>
  );
};

export default EventsFeed;

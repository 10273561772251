import React, { useState, useEffect } from 'react';
import { ListItem, Grid, Icon, ListHeader, ListDescription, Divider, Dropdown, Button, Input, TextArea, Modal, Form, Label } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import TaskCourseMaterial from './TaskCourseMaterial';
import ReactMarkdown from 'react-markdown';

const TaskStatusDropdown = ({ task, handleTaskStatusChange, isCreatorOrParticipant }) => {
  const statusOptions = [
    { key: 'to_do', text: 'To Do', value: 'To Do', color: 'grey' },
    { key: 'in_progress', text: 'In Progress', value: 'In Progress', color: 'yellow' },
    { key: 'done', text: 'Done', value: 'Done', color: 'green' },
  ];

  const [status, setStatus] = useState(task.taskStatus || 'To Do');
  const [color, setColor] = useState(statusOptions.find(option => option.value === status).color);

  const handleStatusChange = (e, { value }) => {
    setStatus(value);
    setColor(statusOptions.find(option => option.value === value).color);
    handleTaskStatusChange(value, task._id);
  };

  useEffect(() => {
    setColor(statusOptions.find(option => option.value === status).color);
  }, [status]);

  return (
    <>
      {isCreatorOrParticipant() ? (
        <Dropdown
        compact
          selection
          options={statusOptions}
          value={status}
          onChange={handleStatusChange}
          style={{ backgroundColor: color }}
        />
      ) : (
        <Label color={color}>
          <Icon name='fly' /> {status}
        </Label>
      )}
    </>
  );
};

const TaskItem = ({ task, isCreatorOrParticipant, isCreator, handleTaskStatusChange, handleAssigneeChange, refetchProjectDetails, teamMemberOptions, forCourseBuilder=false, courseGenerationComplete=false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTask, setEditedTask] = useState({ 
    ...task, 
    topics_to_cover: task.topics_to_cover || [], 
    resourceLinksMap: task.resourceLinksMap || {} 
  });
  const [newTopic, setNewTopic] = useState('');
  const [newResourceKey, setNewResourceKey] = useState('');
  const [newResourceValue, setNewResourceValue] = useState('');

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e, { name, value }) => {
    setEditedTask({ ...editedTask, [name]: value });
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${editedTask._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(editedTask),
      });

      if (response.ok) {
        toast.success('Task updated successfully!');
        setIsEditing(false);
        refetchProjectDetails();
      } else {
        toast.error('Failed to update task');
      }
    } catch (error) {
      console.error('Error updating task:', error);
      toast.error('Failed to update task');
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${taskId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.ok) {
        toast.success('Task deleted successfully!');
        refetchProjectDetails();
      } else {
        toast.error('Failed to delete task');
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      toast.error('Failed to delete task');
    }
  };

  const handleAddTopic = () => {
    if (newTopic.trim() !== '') {
      setEditedTask({
        ...editedTask,
        topics_to_cover: [...editedTask.topics_to_cover, newTopic],
      });
      setNewTopic('');
    }
  };

  const handleRemoveTopic = (index) => {
    const updatedTopics = [...editedTask.topics_to_cover];
    updatedTopics.splice(index, 1);
    setEditedTask({ ...editedTask, topics_to_cover: updatedTopics });
  };

  const handleAddResourceLink = () => {
    if (newResourceKey.trim() !== '' && newResourceValue.trim() !== '') {
      setEditedTask({
        ...editedTask,
        resourceLinksMap: { ...editedTask.resourceLinksMap, [newResourceKey]: newResourceValue },
      });
      setNewResourceKey('');
      setNewResourceValue('');
    }
  };

  const handleRemoveResourceLink = (key) => {
    const updatedResources = { ...editedTask.resourceLinksMap };
    delete updatedResources[key];
    setEditedTask({ ...editedTask, resourceLinksMap: updatedResources });
  };

  return (
    <ListItem>
      <Grid>
        <Grid.Row>
          {!forCourseBuilder ? <Grid.Column width={3}>
            <TaskStatusDropdown task={task} isCreatorOrParticipant={isCreatorOrParticipant} handleTaskStatusChange={handleTaskStatusChange} />
          </Grid.Column>: null}
          <Grid.Column width={forCourseBuilder ? 14 : 11}>
            <ListHeader>{task.content}</ListHeader>
            {task.description && <ListDescription>{task.description}</ListDescription>}
            <ListDescription>
              Start: <strong>{new Date(task.start).toISOString().split('T')[0]}</strong> | End: <strong>{new Date(task.end).toISOString().split('T')[0]}</strong>
            </ListDescription>
            {task.topics_to_cover && task.topics_to_cover.length > 0 && (
              <>
              <Divider />
              <ListDescription>
                <strong>Topics to Cover:</strong>
                <div style={{margin: '5px 0'}}>
                  {task.topics_to_cover.map((topic, index) => (
                    <Label key={index}>
                      {topic}
                    </Label>
                  ))}
                </div>
              </ListDescription>
              </>
            )}
            {
              task.additionalContext && <>
                <Divider />
                <ListDescription>
                  <strong>
                  Additional Context:
                  </strong>
                  <div style={{margin: '5px 0'}}>
                  <ReactMarkdown>{task.additionalContext}</ReactMarkdown>
                  </div>
                  </ListDescription>
              </>
            }
            {task.resourceLinksMap && Object.keys(task.resourceLinksMap).length > 0 && (
              <>
                <ListDescription>
                  <strong>
                    Resource Links:
                  </strong>
                  <div style={{margin: '5px 0'}}>
                    {Object.keys(task.resourceLinksMap).map((key, index) => (
                      <Label key={index}>
                        <Icon name='linkify' />
                        <a href={task.resourceLinksMap[key]} target='_blank' rel='noopener noreferrer'>{key}</a>
                      </Label>
                    ))}
                  </div>
                </ListDescription>
              </>
            )}
          </Grid.Column>
          <Grid.Column width={1}>
            {isCreatorOrParticipant() && (
              <Icon name='edit' onClick={handleEditToggle} style={{ cursor: 'pointer' }} />
            )}
          </Grid.Column>
          <Grid.Column width={1}>
            {isCreator && (
              <Icon name='trash' onClick={() => handleDeleteTask(task._id)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
            )}
          </Grid.Column>
        </Grid.Row>
        {isCreatorOrParticipant() && !forCourseBuilder && (
          <Grid.Row>
            <Grid.Column width={16}>
              <label>Select Assignee(s):</label>
              <Dropdown
                placeholder="Assignee(s)"
                name="assignees"
                fluid
                selection
                multiple
                options={teamMemberOptions}
                value={task.assignees}
                onChange={(e, { value }) => handleAssigneeChange(value, task._id)}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {
          (forCourseBuilder && courseGenerationComplete) ? <TaskCourseMaterial taskId={task._id} isCreator={isCreator}/> : null
        }
      </Grid>
      <Divider />
      {/* Edit Task Modal */}
      <Modal open={isEditing} onClose={handleEditToggle}>
        <Modal.Header>Edit Task</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field required>
              <label>Content</label>
              <Input
                name="content"
                value={editedTask.content}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea
                name="description"
                value={editedTask.description}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              {editedTask.topics_to_cover.length > 0 ? <label>Topics to Cover</label> : null}
              <div>
                {editedTask.topics_to_cover.map((topic, index) => (
                  <Label key={index}>
                    {topic}
                    {isCreator && <Icon name='delete' onClick={() => handleRemoveTopic(index)} />}
                  </Label>
                ))}
              </div>
              {isCreator && (
                <Input
                  placeholder='Add new topic'
                  value={newTopic}
                  onChange={(e) => setNewTopic(e.target.value)}
                  action={{
                    color: 'teal',
                    labelPosition: 'right',
                    icon: 'add',
                    content: 'Add',
                    onClick: handleAddTopic
                  }}
                />
              )}
            </Form.Field>
            <Form.Field>
              {editedTask.additionalContext ? <label>Additional Context</label> : null}
              {isCreator ? <TextArea
                name="additionalContext"
                value={editedTask.additionalContext}
                onChange={handleChange}
              /> :
              <div>
                {editedTask.additionalContext}
              </div>
              }
            </Form.Field>
            <Form.Field>
              {Object.keys(editedTask.resourceLinksMap).length > 0 ? <label>Resource Links</label> : null}
              <div>
                {Object.keys(editedTask.resourceLinksMap).map((key, index) => (
                  <Label key={index}>
                    <Icon name='linkify' />
                    <a href={editedTask.resourceLinksMap[key]} target='_blank' rel='noopener noreferrer'>{key}</a>
                    {isCreator && <Icon name='delete' onClick={() => handleRemoveResourceLink(key)} />}
                  </Label>
                ))}
              </div>
              {isCreator && (
                <>
                  <Input
                    placeholder='Description'
                    value={newResourceKey}
                    onChange={(e) => setNewResourceKey(e.target.value)}
                    style={{ marginBottom: '10px' }}
                  />
                  <Input
                    placeholder='URL'
                    value={newResourceValue}
                    onChange={(e) => setNewResourceValue(e.target.value)}
                    action={{
                      color: 'teal',
                      labelPosition: 'right',
                      icon: 'add',
                      content: 'Add',
                      onClick: handleAddResourceLink
                    }}
                  />
                </>
              )}
            </Form.Field>
            <Form.Field required>
              <label>Start Date</label>
              <Input
                type="date"
                name="start"
                value={new Date(editedTask.start).toISOString().split('T')[0]}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field required>
              <label>End Date</label>
              <Input
                type="date"
                name="end"
                value={new Date(editedTask.end).toISOString().split('T')[0]}
                onChange={handleChange}
              />
            </Form.Field>
            <Button type="button" disabled={!editedTask.content || !editedTask.start || !editedTask.end} onClick={handleSave} color="green">Save</Button>
            <Button type="button" onClick={handleEditToggle} color="red">Cancel</Button>
          </Form>
        </Modal.Content>
      </Modal>
    </ListItem>
  );
};

export default TaskItem;

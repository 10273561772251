import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Pagination, Menu, Message, Grid, Input, Button, Icon, Loader } from 'semantic-ui-react';
import { withNoSelection, sanitizeString } from '../displayHelpers';
import { toast } from 'react-toastify';
import { checkTokenValidity } from '../common_frontend_requests';
import ProjectListItem from './ProjectListItem';
import AddProject from './AddProject';

const ProjectsList = ({ mine = false, forCourses=false }) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filtersLoading, setFiltersLoading] = useState(false);
    const [error, setError] = useState('');
    const [timelineModalOpen, setTimelineModalOpen] = useState({});
    const [applicationModalOpen, setApplicationModalOpen] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [application, setApplication] = useState({
      role: '',
      whyInterested: '',
      timeCommitment: ''
    });
    const [filters, setFilters] = useState({
      projectType: forCourses ? 'teachable_project' : 'none',
      status: '',
      page: 1,
      limit: 10,
    });
    const [title, setTitle] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [projectTypeOptions, setProjectTypeOptions] = useState(withNoSelection([]));

    const toggleTimelineModal = (projectId) => {
      setTimelineModalOpen(prev => ({
          ...prev,
          [projectId]: !prev[projectId]
      }));
    };
  
    const toggleApplicationModal = (projectId) => {
      setApplicationModalOpen(prev => ({
          ...prev,
          [projectId]: !prev[projectId]
      }));
    };

    const validateToken = async () => {
      const data = await checkTokenValidity();
      setIsLoggedIn(data.isValid);
    };

    const isCreator = (creatorId) => {
        return localStorage.getItem('userId') === creatorId;
    };

    const fetchProjects = async () => {
      setLoading(true);
      
      try {
          const query = mine ? 'api/projects/get_mine' : `api/projects/search?${new URLSearchParams({...filters, title}).toString()}`;
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/${query}`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
          });
          if (!response.ok) {
              throw new Error('Failed to fetch projects');
          }
          const data = await response.json();
          setProjects(data.projects);
          if (!mine) {
            setTotalPages(data.totalPages || 0);
          }
  
          // Initialize modal states for both timeline and application modals
          let timelineModalStates = {};
          let applicationModalStates = {};
          data && data.projects?.forEach(project => {
              timelineModalStates[project._id] = false;
              applicationModalStates[project._id] = false;
          });
          setTimelineModalOpen(timelineModalStates);
          setApplicationModalOpen(applicationModalStates);
      } catch (err) {
          setError(err.message);
      } finally {
          setLoading(false);
      }
  };

  const fetchFilters = async () => {
    setFiltersLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/filters`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
      });

      if (!response.ok) throw new Error('Failed to fetch filters');

      const data = await response.json();

      // remove crash course as a filter
      let projectTypes = data.projectTypes.filter(el => el !== 'teachable_project')
     
      setProjectTypeOptions(
        withNoSelection(
          projectTypes.map(el => (
            {
              key: el,
              value: el,
              text: sanitizeString(el), // flakey one-off handling
            }
          ))
        )
      );
    } catch (error) {
      console.error('Error fetching filters:', error);
      setError('Failed to fetch filters');
    } finally {
      setFiltersLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
    fetchFilters();
  }, []);

    useEffect(() => {
        const updatedFilters = { ...filters, projectType: forCourses ? 'teachable_project' : 'none' };
        setFilters(updatedFilters);
    }, [forCourses, mine]);

    useEffect(() => {
        fetchProjects(filters);
    }, [filters]);

    const handleInputChange = (e, { name, value }) => {
      setApplication(prev => ({ ...prev, [name]: value }));
    };

    const handleFilterInputChange = (e, { name, value }) => {
      setFilters({ ...filters, [name]: value, page: 1 }); // Reset to the first page on any filter change
    };

    const handlePageChange = (e, { activePage }) => {
      setFilters({ ...filters, page: activePage });
    };

    const handleSubmitApplication = async (projectId, solo=false) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/apply/${projectId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({
            role: application.role,
            whyInterested: application.whyInterested,
            timeCommitment: application.timeCommitment,
            solo
          })
        });
        if (response.status === 409) {
          toast.warning("You have already applied to this project");
          return;
        }
        if (!response.ok) {
          throw new Error('Failed to submit application');
        }
        toggleApplicationModal(projectId); // Close the modal on success
        setApplication({ role: '', whyInterested: '', timeCommitment: '' }); // Reset application form
        toast.success('Application submitted successfully!');
        const result = await response.json()
        return result
      } catch (error) {
        console.error('Error submitting application:', error);
        toast.error('Failed to submit application');
      }
    };

    const handleSearchInputChange = (e, { value }) => {
      setTitle(value);
    };

    const handleSearchClick = () => {
      fetchProjects();
    };

    if (loading) return <Message>Loading {forCourses ? 'Courses' : 'Projects'}...</Message>;
    if (error) return <Message error>Error: {error}</Message>;

    return (
        <Grid padded>
          <AddProject forCourses={forCourses}/>
          {!mine && (
            <Grid.Row>
                <Menu stackable>
                    <Menu.Item header>Filters</Menu.Item>
                    <Input
                        style={{ margin: 10 }}
                        placeholder='Search by Title'
                        value={title}
                        onChange={handleSearchInputChange}
                        action={
                          <Button icon onClick={handleSearchClick}>
                            <Icon name='search' />
                          </Button>
                        }
                    />
                    {!forCourses ? <Dropdown
                        style={{ margin: 10 }}
                        placeholder='Select Project Type'
                        fluid
                        selection
                        options={projectTypeOptions}
                        value={filters.projectType}
                        onChange={(e, data) => handleFilterInputChange(e, { name: 'projectType', value: data.value })}
                    /> : null}
                </Menu>
            </Grid.Row>
            )}
            
            <Grid.Row>
            {loading ? (
                <Loader active inline="centered" />
              ) : (
              projects.length > 0 ? 
              <Card.Group centered>
                {projects.map((project) => (
                  <ProjectListItem
                    key={project._id}
                    project={project}
                    isLoggedIn={isLoggedIn}
                    isCreator={isCreator(project.project_creator)}
                    toggleTimelineModal={toggleTimelineModal}
                    toggleApplicationModal={toggleApplicationModal}
                    timelineModalOpen={timelineModalOpen[project._id]}
                    applicationModalOpen={applicationModalOpen[project._id]}
                    handleInputChange={handleInputChange}
                    handleSubmitApplication={handleSubmitApplication}
                    application={application}
                    fetchProjects={fetchProjects}
                  />
                ))}
              </Card.Group>
               : 
              <Message info>
                  <Message.Header>No Projects Found</Message.Header>
                  <p>No projects match the selected criteria. Please adjust the filters to view more projects.</p>
              </Message>
              )}
            </Grid.Row>
            
            {!mine && (
              <Grid.Row>
                <Pagination
                    activePage={filters.page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
              </Grid.Row>
            )}
        </Grid>
    );
};

export default ProjectsList;
